<template>
  <div v-html="compiledHtml"></div>
</template>




<script>
// import baseUrl from '../../../util/config'
let baseUrl = 'http://localhost:8081'

export default {
  data() {
    return {
      fileName: "Time_Series_Model.html",
      input: ""
    };
  },


  mounted() {
    this.loadFile()
  },


  // created() {
  //   // this.fileName = this.$route.params.fileName;
  //   this.loadFile()
  // },


  computed: {
    compiledHtml: function () {
      return this.input;
    }
  },

  methods: {
    loadFile() {
      this.$axios({
        method: "get",
        url: "../../static/html4DataModels/" + this.fileName
      })
        .then(result => {
          this.input = result.data;
          // console.log(url);
        })
        .catch(error => {
          console.error("error getting file");
          console.error(url);
        });
    }
  },





}
</script>

<style scoped>

</style>